<template>
  <div class="space-y-4" role="list">
    <default-setting-row
      title="注文弁当"
      label="日替わり弁当"
      value="日替わり弁当"
      :is-changeable="false"
      role="list-item"
    />
    <default-setting-row
      title="個数"
      label="1"
      :value="1"
      :is-changeable="false"
      role="list-item"
    />
    <default-setting-row
      title="支払い方法"
      label="クレジットカード"
      value="credit_card"
      :is-changeable="true"
      role="list-item"
    />
  </div>
</template>
