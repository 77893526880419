<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/vue';

import type { Engine, Container } from '@tsparticles/engine';
import type { RouteLocationRaw } from '#vue-router';

import type { PurchaseLogSchema } from '~/schemas/purchase_log_schema';

interface Emits {
  (e: 'close', to: RouteLocationRaw): void;
}
defineEmits<Emits>();

interface Props {
  isOpen: boolean;
  purchaseLog: PurchaseLogSchema;
}
withDefaults(defineProps<Props>(), {});

const tsparticlesEngine = ref<Engine>();
const backToHomeButton = ref<HTMLButtonElement>();

const particlesLoaded = (_container: Container) => {
  // console.log('Particles container loaded', container);
  // container は今回不要
  const particles = tsparticlesEngine.value?.domItem(0);
  setTimeout(() => {
    particles?.stop();
  }, 6000);
};
const particlesOptions = {
  particles: {
    number: {
      value: 125, // この数値を変更すると紙吹雪の数が増減できる
      density: {
        enable: false,
        value_area: 400,
      },
    },
    color: {
      value: [
        '#EA5532',
        '#F6AD3C',
        '#FFF33F',
        '#00A95F',
        '#00ADA9',
        '#00AFEC',
        '#4D4398',
        '#E85298',
      ], // 紙吹雪の色の数を増やすことが出来る
    },
    shape: {
      type: 'polygon', // 形状はpolygonを指定
      stroke: {
        width: 0,
      },
      polygon: {
        nb_sides: 5, // 多角形の角の数
      },
    },
    opacity: {
      value: 1,
      random: false,
      anim: {
        enable: true,
        speed: 20,
        opacity_min: 0,
        sync: false,
      },
    },
    size: {
      value: 5.305992965476349,
      random: true, // サイズをランダムに
      anim: {
        enable: true,
        speed: 1.345709068776642,
        size_min: 0.8,
        sync: false,
      },
    },
    line_linked: {
      enable: false,
    },
    move: {
      enable: true,
      speed: 10, // この数値を小さくするとゆっくりな動きになる
      direction: 'bottom', // 下に向かって落ちる
      random: false, // 動きはランダムにならないように
      straight: false, // 動きをとどめない
      out_mode: 'out', // 画面の外に出るように描写
      bounce: false, // 跳ね返りなし
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1200,
      },
    },
  },
  interactivity: {
    detect_on: 'canvas',
    events: {
      onhover: {
        enable: false,
      },
      onclick: {
        enable: false,
      },
      resize: true,
    },
  },
  retina_detect: true,
};
</script>

<template>
  <Dialog
    :open="isOpen"
    :initial-focus="backToHomeButton"
    class="relative z-50"
    @close="() => $emit('close', { name: 'index' })"
  >
    <div class="fixed inset-0 bg-black/30">
      <vue-particles
        id="tsparticles"
        :options="particlesOptions"
        aria-hidden="true"
        @particles-loaded="particlesLoaded"
      />
    </div>

    <div class="fixed inset-0 overflow-y-auto">
      <div class="flex min-h-full items-center justify-center p-4">
        <DialogPanel
          class="w-full max-w-xl rounded bg-white px-4 pb-4 pt-5 shadow-xl"
        >
          <DialogTitle>ご購入ありがとうございました。</DialogTitle>

          <div class="space-y-8">
            <item-quantity-list :items="purchaseLog.purchase_detail_logs">
              <template #footer>
                <TotalPrice
                  :price="purchaseLog.amount"
                  :fixed-price="purchaseLog.payment_amount"
                />
              </template>
            </item-quantity-list>

            <PurchasePaymentDetail class="text-sm" :log="purchaseLog" />

            <p>温めてからお召し上がりください。</p>

            <div v-if="false" class="rounded-md border px-4 py-6">
              <h3 class="mb-2 text-lg font-bold">デフォルト設定</h3>

              <p class="text-sm text-gray-600">
                今回の内容をデフォルトに設定することで次回から初期選択され、支払いが楽になります。
              </p>

              <default-setting-list class="mt-4" />
            </div>

            <div class="space-y-2">
              <button
                type="button"
                class="inline-flex w-full items-center justify-center gap-x-2 rounded-md border border-gray-600 px-3.5 py-2.5 text-sm font-semibold text-gray-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                @click="
                  $emit('close', {
                    name: 'purchase-item',
                    query: {
                      l: purchaseLog.location.id,
                      p: purchaseLog.position.name,
                    },
                  })
                "
              >
                続けて購入する
              </button>
              <button
                ref="backToHomeButton"
                type="button"
                class="inline-flex w-full items-center justify-center gap-x-2 rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                @click="() => $emit('close', { name: 'index' })"
              >
                ホームへ戻る
              </button>
            </div>
          </div>
        </DialogPanel>
      </div>
    </div>
  </Dialog>
</template>
